import React from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { BrowserRouter } from 'react-router-dom'
import OOORouter from './pages/OOORouter'

const clientId = process.env.REACT_APP_CLIENT_ID || ''

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <BrowserRouter>
        <OOORouter />
      </BrowserRouter>
    </GoogleOAuthProvider>
  )
}

export default App
