import React from 'react'
import { QuestionCircleTwoTone } from '@ant-design/icons'
import { Form, Select, Spin, TimePicker, DatePicker, Input, Skeleton, Tooltip } from 'antd'
import { Dayjs } from 'dayjs'

import { DurationConfig, LocationConfig, DateConfig, TimeConfig, LinkConfig } from './formConfigs'
import { FormInstance } from 'antd/lib/form'

import travelPerkTutorial from '../../../media/get-travelperk-link.mp4'

const { Option } = Select

const overlay = (
  <video autoPlay loop controls style={{ maxWidth: '100%' }}>
    <source src={travelPerkTutorial} type='video/mp4' />
    Your browser does not support the video element.
  </video>
)

interface FormSelectorFieldProps {
  disabled: boolean
  name: string
  label: string
  handleChange: (value: string) => void
  selectorData: { id: string; name: string }[]
  loading: boolean
}

interface FormPickerFieldProps {
  disabled: boolean
  name: string
  label: string
  handleChange: (value: Dayjs | null) => void
  testId: string
  loading: boolean
}

interface FormDuarationFieldProps {
  disabled: boolean
  name: string
  label: string
  handleChange: (value: Dayjs | null) => void
  testId: string
  loading: boolean
}
interface InputFieldProps {
  name: string
  label: string
  testId: string
  loading: boolean
}
interface InputURLFieldProps {
  name: string
  testId: string
  loading: boolean
  disabled: boolean
  handleChange: () => void
}

export const SelectorField: React.FC<FormSelectorFieldProps> = ({
  disabled,
  name,
  label,
  handleChange,
  selectorData,
  loading,
}) => (
  <Form.Item name={name} label={label} {...LocationConfig}>
    {loading ? (
      <Skeleton.Input size='large' active />
    ) : (
      <Select
        disabled={disabled}
        className='form-input-borderless'
        onChange={handleChange}
        notFoundContent={<Spin tip='Loading locations' />}
      >
        {selectorData.map((data) => (
          <Option key={data.id} value={data.id}>
            {data.name}
          </Option>
        ))}
      </Select>
    )}
  </Form.Item>
)

export const TimePickerField: React.FC<FormPickerFieldProps> = ({
  disabled,
  name,
  label,
  handleChange,
  testId,
  loading,
}) => (
  <Form.Item name={name} label={label} {...TimeConfig}>
    {loading ? (
      <Skeleton.Input size='large' active />
    ) : (
      <TimePicker
        disabled={disabled}
        format='HH:mm'
        clearIcon
        className='form-input'
        data-testid={testId}
        onChange={handleChange}
        showNow={false}
      />
    )}
  </Form.Item>
)

export const DurationPickerField: React.FC<FormDuarationFieldProps> = ({
  disabled,
  name,
  label,
  handleChange,
  testId,
  loading,
}) => (
  <Form.Item name={name} label={label} {...DurationConfig}>
    {loading ? (
      <Skeleton.Input size='large' active />
    ) : (
      <TimePicker
        disabled={disabled}
        format='HH:mm'
        clearIcon
        className='form-input'
        data-testid={testId}
        onChange={handleChange}
        showNow={false}
      />
    )}
  </Form.Item>
)

export const DatePickerField: React.FC<FormPickerFieldProps> = ({
  disabled,
  name,
  label,
  handleChange,
  testId,
  loading,
}) => (
  <Form.Item name={name} label={label} {...DateConfig}>
    {loading ? (
      <Skeleton.Input size='large' active />
    ) : (
      <DatePicker
        disabled={disabled}
        clearIcon
        className='form-input'
        data-testid={testId}
        onChange={handleChange}
      />
    )}
  </Form.Item>
)

export const InputField: React.FC<InputFieldProps> = ({ name, label, testId, loading }) => (
  <Form.Item name={name} label={label}>
    {loading ? (
      <Skeleton.Input size='large' active />
    ) : (
      <Input type='text' className='form-input' data-testid={testId} disabled />
    )}
  </Form.Item>
)

export const InputURLField: React.VFC<InputURLFieldProps> = ({
  name,
  testId,
  loading,
  disabled,
  handleChange,
}) => {
  const [input, setInput] = React.useState('')
  const isMountedRef = React.useRef(false)

  React.useEffect(() => {
    if (isMountedRef.current) {
      const getData = setTimeout(() => {
        handleChange()
      }, 1000)
      return () => clearTimeout(getData)
    } else {
      isMountedRef.current = true
    }
  }, [input])

  return (
    <Form.Item
      name={name}
      label={
        <Tooltip overlay={overlay}>
          <span>
            Link of the ticket (URL) <QuestionCircleTwoTone />
          </span>
        </Tooltip>
      }
      {...LinkConfig}
    >
      {loading ? (
        <Skeleton.Input size='large' active style={{ width: '456px' }} />
      ) : (
        <Input
          type='text'
          className='form-input-full'
          data-testid={testId}
          disabled={disabled}
          onChange={(e) => setInput(e.target.value)} // Modify the onChange event handler
        />
      )}
    </Form.Item>
  )
}

export const updateFields = (form: FormInstance, fieldNames: string[]) => {
  fieldNames.forEach((fieldName) => {
    const outFieldName = fieldName.replace('in', 'out')
    const fieldValue = form.getFieldValue(fieldName) ?? form.getFieldValue(outFieldName)
    form.setFieldValue(fieldName, fieldValue)
  })
}
