import React, { useEffect, useState } from 'react'
import logo from '../../media/logo.svg'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../pages/routes'
import { Avatar, Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { useUserData } from '../../hooks/useUserData'

const Header: React.FC = () => {
  const [userPicture, setUserPicture] = useState('')

  useEffect(() => {
    const userData = useUserData()
    setUserPicture(userData.picture)
  }, [])

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to={ROUTES.history}>Business trip history</Link>,
    },
    {
      key: '2',
      label: <Link to={ROUTES.home}>Logout</Link>,
      onClick: () => sessionStorage.clear(),
    },
  ]

  return (
    <header>
      <div className='combined'>
        <Link to={ROUTES.history}>
          <img src={logo} alt='logo' />
        </Link>
        <h3 className='logo-text'>OOO TRAVEL</h3>
      </div>
      <Dropdown menu={{ items }} placement='bottomRight'>
        <Avatar className='avatar' src={userPicture} alt='avatar' size={32} />
      </Dropdown>
    </header>
  )
}

export default Header
