import jwtDecode from 'jwt-decode'

export const useUserData = () => {
  const jwtToken = sessionStorage.getItem('jwtToken')
  let userData
  if (jwtToken) {
    const decodedToken = JSON.stringify(jwtDecode(jwtToken))
    userData = JSON.parse(decodedToken)
  }
  return userData
}
