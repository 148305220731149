import React from 'react'
import FlightForm from './FlightForm'
import Header from '../../components/layout/Header'
import Footer from '../../components/layout/Footer'

const FlightFormPage: React.FC = () => (
  <div className='wrapper'>
    <Header />
    <FlightForm />
    <Footer />
  </div>
)

export { FlightFormPage as FlightForm }
