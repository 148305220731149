import React from 'react'
import FlightHistory from './FlightHistory'
import Header from '../../components/layout/Header'
import Footer from '../../components/layout/Footer'

const FlightHistoryPage: React.FC = () => (
  <div className='wrapper'>
    <Header />
    <FlightHistory />
    <Footer />
  </div>
)

export default FlightHistoryPage
