import React, { useState, useEffect } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Divider, Button, message, Skeleton } from 'antd'
import FlightList from '../../components/FlightHistory/FlightList'
import { ROUTES } from '../routes'
import { Link, useNavigate } from 'react-router-dom'
import { getTripList } from '../../components/FlightHistory/FlightHistoryApiCalls'
import { Flight } from '../../components/FlightHistory/FlightList'

export default function FlightHistory() {
  const [tripList, setTripList] = useState<Flight[]>([])
  const [loadingList, setLoadingList] = useState(true)

  const navigate = useNavigate()
  useEffect(() => {
    getTripList()
      .then((data) => {
        setTripList(data.reimbursements)
      })
      .catch(() => {
        navigate(ROUTES.home)
        sessionStorage.clear()
        message.error('Somthing went wrong')
      })
      .finally(() => setLoadingList(false))
  }, [])

  return (
    <div className='trip-view'>
      <h2 className='trip-view-text'>Business trip history</h2>
      <Divider style={{ borderWidth: 2, borderColor: 'black' }} />
      <Skeleton loading={loadingList} active title={false} paragraph={{ rows: 4 }}>
        <FlightList flights={tripList} />
      </Skeleton>

      <Link to={ROUTES.form}>
        <Button
          danger
          type='primary'
          icon={<PlusCircleOutlined />}
          className='trip-view-button'
        >
          ADD NEW BUSINESS TRIP
        </Button>
      </Link>
    </div>
  )
}
