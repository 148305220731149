import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(utc)

export const formatFlightTimeStamp = (
  startDate: Dayjs,
  startTime: Dayjs,
  timezone = 'Europe/Vilnius'
) => {
  const fullDate = dayjs()
    .year(startDate.year())
    .month(startDate.month())
    .date(startDate.date())
    .hour(startTime.hour())
    .minute(startTime.minute())
    .second(0)
    .millisecond(0)
    .tz(timezone, true)
    .toISOString()

  return fullDate
}

export const formatDuration = (flightDuration: Dayjs) =>
  dayjs
    .duration({
      hours: Number(flightDuration.format('HH')),
      minutes: Number(flightDuration.format('mm')),
    })
    .asMinutes()
