import React from 'react'
import { Navigate, Outlet } from 'react-router'

const CheckUser: React.FC = () => {
  const user = sessionStorage.getItem('jwtToken')

  if (!user) {
    return <Navigate to='/' replace />
  }

  return <Outlet />
}

export default CheckUser
