import React from 'react'
import { FlightForm } from './FlightForm/index'
import LandingPage from './LandingPage/index'
import FlightHistory from './FlightHistory/index'
import { Navigate, Route, Routes } from 'react-router'
import CheckUser from '../components/CheckUser/CheckUser'
import { ROUTES } from './routes'

const OOORouter = () => {
  return (
    <Routes>
      <Route element={<CheckUser />}>
        <Route path={ROUTES.form} element={<FlightForm />} />
        <Route path={`${ROUTES.form}/:UUID`} element={<FlightForm />} />
        <Route path={ROUTES.history} element={<FlightHistory />} />
      </Route>
      <Route path={ROUTES.anyLink} element={<Navigate to={ROUTES.home} />} />
      <Route path={ROUTES.home} element={<LandingPage />} />
    </Routes>
  )
}

export default OOORouter
