import React from 'react'
import { List } from 'antd'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../pages/routes'

export interface Flight {
  id: string
  employmentLocation: {
    name: string
  }
  outLocation: {
    name: string
  }
  outFlightStartTimestamp: string
  status: string
}

function FlightList(props: { flights: Flight[] }) {
  if (props.flights.length === 0) {
    return <p>There are no flights in the list.</p>
  }

  const sortedFlights = props.flights.sort((a, b) => {
    return (
      new Date(b.outFlightStartTimestamp).getTime() - new Date(a.outFlightStartTimestamp).getTime()
    )
  })
  return (
    <List
      split={false}
      dataSource={sortedFlights}
      renderItem={(flight) => {
        const formattedTimestamp = dayjs(flight.outFlightStartTimestamp).format('YYYY-MM-DD')
        return (
          <List.Item>
            <Link to={`${ROUTES.form}/${flight.id}`} className='trip-view-link'>
              {flight.employmentLocation.name} - {flight.outLocation.name}{' '}
              <span className='trip-view-nowrap'>{formattedTimestamp}</span>
            </Link>
            <span
              className={
                flight.status === 'DRAFT'
                  ? 'trip-view-status gray padding-x-sm padding-y-s'
                  : 'trip-view-status blue padding-x-sm padding-y-s'
              }
            >
              {flight.status}
            </span>
          </List.Item>
        )
      }}
    />
  )
}

export default FlightList
