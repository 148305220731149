import React from 'react'
import { Row, Col } from 'antd'
import {
  SelectorField,
  TimePickerField,
  DatePickerField,
  DurationPickerField,
} from './FormFields/FormInputs'
import { Location } from '../../pages/FlightForm/FlightForm'

const FormInbound: React.FC<{
  disabled: boolean
  handleChange: () => void
  airports: Location[]
  destinationLocations: Location[]
  loading: boolean
}> = ({ disabled, handleChange, airports, destinationLocations, loading }) => {
  return (
    <>
      <label className='form-type-label'>INBOUND</label>

      <Row gutter={16}>
        <Col>
          <DatePickerField
            disabled={disabled}
            name='inFlightStartDate'
            label='Flight start date'
            handleChange={handleChange}
            testId='inFlightStartDate'
            loading={loading}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <SelectorField
            disabled={disabled}
            name='inLocationId'
            label='Travel start city'
            handleChange={handleChange}
            selectorData={destinationLocations}
            loading={loading}
          />
        </Col>
        <Col>
          <TimePickerField
            disabled={disabled}
            name='inFlightStartTime'
            label='Flight start time'
            testId='inFlightStartTime'
            handleChange={handleChange}
            loading={loading}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <SelectorField
            disabled={disabled}
            name='inAirportId'
            label='Arrival Airport'
            handleChange={handleChange}
            selectorData={airports}
            loading={loading}
          />
        </Col>
        <Col>
          <DurationPickerField
            disabled={disabled}
            name='inDurationOfFlight'
            label='Flight duration'
            testId='inDurationOfFlight'
            handleChange={handleChange}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  )
}
export default FormInbound
