import { Dayjs } from 'dayjs'
import { formatDuration } from '../../../time-utils'

export const DateConfig = {
  rules: [
    {
      type: 'object' as const,
      required: true,
      message: 'Please select date!',
    },
  ],
}

export const TimeConfig = {
  rules: [
    {
      type: 'object' as const,
      required: true,
      message: 'Please select time!',
    },
  ],
}

export const DurationConfig = {
  rules: [
    {
      type: 'object' as const,
      required: true,
      message: 'Please select time!',
    },
    {
      validator(rule: object, value: Dayjs) {
        if (formatDuration(value) < 30) {
          return Promise.reject('Minimum duration: 30 minutes')
        }
        return Promise.resolve()
      },
    },
  ],
}

export const LocationConfig = {
  rules: [
    {
      required: true,
      message: 'Please select a location!',
    },
  ],
}

export const LinkConfig = {
  rules: [
    {
      required: true,
      message: 'Please input travelperk link',
    },
    {
      validator(rule: object, value: string) {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
        if (!value.match(urlRegex)) {
          return Promise.reject('Invalid URL format')
        }
        return Promise.resolve()
      },
    },
  ],
}
