import React from 'react'
import dayjs from 'dayjs';

const currentYear = dayjs().format('YYYY');


const Footer: React.FC = () => {
  return (
    <footer>
      <p className='footer-text'>Zenitech © All Rights Reserved {currentYear }</p>
    </footer>
  )
}

export default Footer
