import React from 'react'
import LandingPage from './LandingPage'

const Homepage: React.FC = () => (
  <div className='wrapper'>
    <LandingPage />
  </div>
)

export default Homepage
