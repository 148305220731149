import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GoogleLogin, CredentialResponse } from '@react-oauth/google'
import { ROUTES } from '../routes'
import { message } from 'antd'

const LandingPage: React.FC = () => {
  const navigate = useNavigate()

  const responseSuccessGoogle = async (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      sessionStorage.setItem('jwtToken', credentialResponse.credential)
      navigate(ROUTES.history)
    }
  }

  return (
    <div className='landing-page'>
      <div className='landing-page-text-and-button'>
        <div className='landing-page-text-and-button-title-subtitle'>
          <h1>
            Welcome to{' '}
            <span className='landing-page-text-and-button-title-subtitle-accent'>OOO Travel</span>
          </h1>
          <p>
          The template here is to help you to get compensated for your out of office travel. 
          Fill out your business trip details and track all your travels in one place.

          </p>
        </div>
        <div className='google-signin'>
          <div id='signInDiv' data-testid='google-login-button'></div>
          <GoogleLogin
            onSuccess={responseSuccessGoogle}
            onError={() => {
              message.error('Login Failed')
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default LandingPage
