import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Spin } from 'antd'
import { Dayjs } from 'dayjs'
import { message } from 'antd'
import { formatFlightTimeStamp, formatDuration } from '../../time-utils'
import { calculateDuration } from '../../services/api'

export interface FormValues {
  outFlightStartDate: Dayjs
  outFlightStartTime: Dayjs
  outDurationOfFlight: Dayjs
  outAirportId: string
  employmentLocationId: string
  outLocationId: string
  inFlightStartDate: Dayjs
  inFlightStartTime: Dayjs
  inDurationOfFlight: Dayjs
  inAirportId: string
  inLocationId: string
}

type Props = {
  isFormSubmitted: (isSubmitted: boolean) => void
  disabled: boolean
  formValues: FormValues
  UUID: string
  isReturnFlight: boolean
  destinationTimezone: string | undefined
}

const FormResults = (props: Props) => {
  const { isFormSubmitted, disabled, formValues, UUID, isReturnFlight, destinationTimezone } = props

  const [totalDurationRes, setTotalDurationRes] = useState({
    totalTravelDuration: 0,
    totalOooDuration: 0,
  })
  const [calculationLoading, setCalculationLoading] = useState(true)

  useEffect(() => {
    setCalculationLoading(true)

    const formatedOutFlightTimeStamp = formatFlightTimeStamp(
      formValues.outFlightStartDate,
      formValues.outFlightStartTime
    )
    const formatedDuration = formatDuration(formValues.outDurationOfFlight)

    const calculateSignleDurationDto = {
      outFlightStartTimestamp: formatedOutFlightTimeStamp,
      outDurationOfFlight: formatedDuration,
      outAirportId: formValues['outAirportId'],
      employmentLocationId: formValues['employmentLocationId'],
      outLocationId: formValues['outLocationId'],
    }

    if (isReturnFlight && formValues.inFlightStartDate) {
      const formatedInFlightTimeStamp = formatFlightTimeStamp(
        formValues.inFlightStartDate,
        formValues.inFlightStartTime,
        destinationTimezone
      )
      const formatedInDuration = formatDuration(formValues.inDurationOfFlight)

      const calculateReturnDurationDto = {
        ...calculateSignleDurationDto,
        inFlightStartTimestamp: formatedInFlightTimeStamp,
        inDurationOfFlight: formatedInDuration,
        inAirportId: formValues['inAirportId'],
        inLocationId: formValues['inLocationId'],
      }
      calculateDuration(calculateReturnDurationDto, UUID)
        .then((response) => {
          const totalTravelDuration =
            response.outTotalTravelDuration + response.inTotalTravelDuration
          const totalOooDuration = response.outTotalOooDuration + response.inTotalOooDuration
          setTotalDurationRes({ totalTravelDuration, totalOooDuration })
        })
        .catch((error) => message.error(`Error: ${error}`))
        .finally(() => setCalculationLoading(false))
    } else {
      calculateDuration(calculateSignleDurationDto, UUID)
        .then((response) => {
          const totalTravelDuration = response.outTotalTravelDuration
          const totalOooDuration = response.outTotalOooDuration
          setTotalDurationRes({ totalTravelDuration, totalOooDuration })
        })
        .catch((error) => message.error(`Error: ${error}`))
        .finally(() => setCalculationLoading(false))
    }
  }, [])

  return (
    <>
      <Spin spinning={calculationLoading} tip='Calculating please wait' />
      {calculationLoading || (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <label className='result-label'>Total travel time</label>
              <div className='form-input' data-testid='total-time-text'>
                {totalDurationRes.totalTravelDuration >= 600
                  ? Math.floor(totalDurationRes.totalTravelDuration / 60)
                  : '0' + Math.floor(totalDurationRes.totalTravelDuration / 60)}
                :
                {totalDurationRes.totalTravelDuration % 60 >= 10
                  ? Math.floor(totalDurationRes.totalTravelDuration % 60)
                  : '0' + Math.floor(totalDurationRes.totalTravelDuration % 60)}
                H
              </div>
            </Col>
            <Col>
              <label className='result-label'>OOO Total travel time</label>
              <div className='form-input'>
                {totalDurationRes.totalOooDuration >= 600
                  ? Math.floor(totalDurationRes.totalOooDuration / 60)
                  : '0' + Math.floor(totalDurationRes.totalOooDuration / 60)}
                :
                {totalDurationRes.totalOooDuration % 60 >= 10
                  ? Math.floor(totalDurationRes.totalOooDuration % 60)
                  : '0' + Math.floor(totalDurationRes.totalOooDuration % 60)}
                H
              </div>
            </Col>
          </Row>

          <Row>
            <Button
              disabled={disabled}
              className='form-submit-button margin-y-l'
              test-id='submit-button'
              type='primary'
              onClick={() => isFormSubmitted(true)}
            >
              SUBMIT
            </Button>
          </Row>
        </>
      )}
    </>
  )
}

export default FormResults
